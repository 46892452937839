<template>
  <v-card tag="section" class="py-4 px-4 mt-4">
    <v-card-title>Login</v-card-title>
    <v-form
      ref="form"
      @submit.prevent="login"
    >
      <v-text-field
        v-model="username"
        autofocus
        label="Email"
        required
      ></v-text-field>

      <v-text-field
        v-model="password"
        :append-icon="showPassword ? $icons.mdiEye : $icons.mdiEyeOff"
        :type="showPassword ? 'text' : 'password'"
        name="password1"
        label="Password"
        required
        :error-messages="errors.nonFieldErrors || []"
        @click:append="showPassword = !showPassword"
      ></v-text-field>
      <v-btn
        color="success"
        type="submit"
        class="mt-4"
        :loading="isLoading"
        :disabled="isLoading"
      >
        Login
      </v-btn>
    </v-form>
  </v-card>
</template>

<script>
import http from '@/http'

export default {
  props: ["next", "logout"],
  data () {
    return {
      errors: {},
      isLoading: false,
      showPassword: false,
      username: "",
      password: "",
    }
  },
  async created () {
    if (this.logout) {
      await this.$store.dispatch('logout')
    }
    if (this.$store.state.authenticated) {
      this.$router.push(this.next)
    }
  },
  methods: {
    async login () {
      let response
      this.isLoading = true
      this.errors = {}
      try {
        response = await http.post('login', {username: this.username, password: this.password})
        if (!response.data.user.permissions.manage_clients) {
          this.errors = {nonFieldErrors: ["Invalid username or password"]}
        }
        else {
          await this.$store.dispatch('handleLogin', response.data)
          this.$router.push(this.next)
        }
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isLoading = false
      }

    }
  }
}
</script>
